import env from '../utils/env';
import { FetchError, GraphQLErrors } from '../utils/errors';
import { getValidAccessToken } from '../utils/oidc';
import { getFile } from './db/recordmutations';
function gql(strings) {
    return strings.raw[0];
}
/**
 * returns a localapp or throws
 *
 * @throws {UserNotLoggedInError | OfflineError | GraphQLErrors | FetchError}
 */
export async function queryAppList() {
    console.time('queryAppList');
    console.info(`[GraphQL] query applist`);
    console.group();
    const headers = new Headers({
        'content-type': 'application/json',
        'authorization': `Bearer ${await getValidAccessToken()}`,
    });
    const response = await fetch(env.graphqlEndpoint, {
        body: JSON.stringify({
            query: gql `
                query AppList {
                    livingapiV1(appId: "5f003b035edd8e0e1b83c914", template: "applist") {
                        datasources {
                            identifier
                            apps {
                                controls {
                                    identifier
                                    label
                                    lookupApp {
                                        id
                                    }
                                    lookupControls {
                                        label
                                        identifier
                                    }
                                    lookupData {
                                        label
                                        key
                                    }
                                    subtype
                                    type
                                }
                                description
                                iconSmall {
                                    url
                                }
                                id
                                name
                                updateDate
                            }
                        }
                    }
                }
            `,
            operationName: 'AppList',
            variables: {}
        }),
        headers,
        method: 'POST',
    });
    if (response.ok) {
        const responseBody = await response.json();
        if (responseBody.errors) {
            console.timeEnd('queryAppList');
            console.warn(`[GraphQL] Errors happend `, responseBody.errors);
            console.groupEnd();
            throw new GraphQLErrors(responseBody.errors.map((err) => err.message));
        }
        console.timeEnd('queryAppList');
        console.debug('[GraphQL] Successfully queried the applist');
        console.groupEnd();
        return responseBody.data.livingapiV1.datasources[0].apps;
    }
    else {
        console.timeEnd('queryAppList');
        console.warn(`[GraphQL] server answered with ${response.status}`);
        console.groupEnd();
        throw new FetchError(response.status, response.statusText);
    }
}
/**
 *
 * @param appId
 * @throws {UserNotLoggedInError | OfflineError | GraphQLErrors | FetchError}
 */
export async function queryRecordList(appId) {
    console.time('queryRecordList');
    console.info(`[GraphQL] query recordlist`);
    console.group();
    const headers = new Headers({
        'content-type': 'application/json',
        'authorization': `Bearer ${await getValidAccessToken()}`,
    });
    const variables = {
        params: JSON.stringify({
            appid: appId,
        })
    };
    const response = await fetch(env.graphqlEndpoint, {
        body: JSON.stringify({
            query: gql `
                query RecordList($params: String!) {
                    livingapiV1(appId: "5b9104c47a998d7abd016859", template: "mydata", params: $params) {
                        datasources {
                            identifier
                            apps {
                                records {
                                    allAppLookups {
                                        identifier
                                        record {
                                            id
                                        }
                                    }
                                    allBoolValues {
                                        identifier
                                        value
                                    }
                                    allDateValues {
                                        identifier
                                        value
                                    }
                                    allFileValues {
                                        identifier
                                        value {
                                            url
                                            filename
                                            mimetype
                                            createDate
                                        }
                                    }
                                    allGeoValues {
                                        identifier
                                        value {
                                            lat
                                            lng
                                            info
                                        }
                                    }
                                    allListAppLookupValues {
                                        identifier
                                        records {
                                            id
                                        }
                                    }
                                    allListLookupValues {
                                        identifier
                                        lookups {
                                            key
                                            label
                                        }
                                    }
                                    allLookupValues {
                                        identifier
                                        lookup {
                                            key
                                            label
                                        }
                                    }
                                    allNumberValues {
                                        identifier
                                        valueFloat
                                    }
                                    allStringValues {
                                        identifier
                                        subtype
                                        value
                                    }
                                    app {
                                        id
                                    }
                                    createDate
                                    updateDate
                                    id
                                }
                            }
                        }
                    }
                }
            `,
            operationName: 'RecordList',
            variables,
        }),
        headers,
        method: 'POST',
    });
    if (response.ok) {
        const responseBody = await response.json();
        if (responseBody.errors) {
            console.timeEnd('queryRecordList');
            console.warn(`[GraphQL] Errors happend `, responseBody.errors);
            console.groupEnd();
            throw new GraphQLErrors(responseBody.errors.map((err) => err.message));
        }
        console.timeEnd('queryRecordList');
        console.debug('[GraphQL] Successfully queried the applist');
        console.groupEnd();
        return responseBody.data.livingapiV1.datasources[0].apps[0].records;
    }
    else {
        console.timeEnd('queryRecordList');
        console.warn(`[GraphQL] server answered with ${response.status}`);
        console.groupEnd();
        throw new FetchError(response.status, response.statusText);
    }
}
export function convertLocalRecordToInputDataV1(record) {
    console.debug('convert localrecord to inputdataV1');
    const inputData = {
        booleanFields: record.allBoolValues.map(boolVal => ({
            identifier: boolVal.identifier,
            value: boolVal.value,
        })),
        dateFields: record.allDateValues.map(dateVal => ({
            identifier: dateVal.identifier,
            value: dateVal.value,
        })),
        fileFields: record.allFileValues.map(fileVal => ({
            identifier: fileVal.identifier,
            file: fileVal.value,
        })),
        geoFields: record.allGeoValues.map(geoVal => ({
            identifier: geoVal.identifier,
            ...geoVal.value
        })),
        lookupFields: record.allLookupValues.map(lookupVal => {
            var _a;
            return ({
                identifier: lookupVal.identifier,
                keys: ((_a = lookupVal.lookup) === null || _a === void 0 ? void 0 : _a.key) ? [lookupVal.lookup.key] : [],
            });
        })
            .concat(record.allListLookupValues.map(listLookupVal => ({
            identifier: listLookupVal.identifier,
            keys: listLookupVal.lookups.map(lookup => lookup.key),
        }))),
        numberFields: record.allNumberValues.map(numVal => ({
            identifier: numVal.identifier,
            valueAsFloat: numVal.valueFloat,
        })),
        stringFields: record.allStringValues.map(strVal => ({
            identifier: strVal.identifier,
            value: strVal.value,
        })),
    };
    return inputData;
}
/**
 *
 * @param record
 * @throws {UserNotLoggedInError | OfflineError | GraphQLErrors | FetchError}
 */
export async function mutateRecord(record) {
    var _a;
    console.time('mutateRecord');
    console.info(`[GraphQL] mutate record ${record.id}`);
    console.group();
    try {
        const headers = new Headers({
            'authorization': `Bearer ${await getValidAccessToken()}`,
            'x-la-gql-reqid': `livingapp-pwa-${Math.floor(Math.random() * 1000)}-${Date.now()}`,
        });
        const formData = new FormData();
        const fileTuples = [];
        const map = {};
        const inputData = convertLocalRecordToInputDataV1(record);
        console.log(inputData.fileFields);
        inputData.fileFields = (_a = inputData.fileFields.filter(fileField => { var _a, _b; return ((_b = (_a = fileField.file) === null || _a === void 0 ? void 0 : _a.url) === null || _b === void 0 ? void 0 : _b.startsWith('idb://')) || fileField.file === undefined; })) !== null && _a !== void 0 ? _a : [];
        for (const [index, field] of inputData.fileFields.entries()) {
            if (field.file !== undefined) {
                const file = await getFile(field.file.url);
                field.file = null;
                if (file.data) {
                    map[String(index)] = [`variables.data.fileFields.${index}.file`];
                    fileTuples.push([String(index), file.data]);
                }
            }
        }
        formData.append('operations', JSON.stringify({
            query: gql `
                mutation saveRecord($appParams: String!, $recordId: String, $appId: String!, $data: InputDataV1!) {
                    saveRecordV1(appId:"5b9104c47a998d7abd016859", template:"mydata", params: $appParams, datasource: {id: "data", appId: $appId}, recordId: $recordId, data:  $data) {
                        id
                    }
                }
            `,
            operationName: 'saveRecord',
            variables: {
                appId: record.app.id,
                appParams: `{"appid": "${record.app.id}"}`,
                recordId: !record.id.startsWith('loc_') ? record.id : undefined,
                data: inputData,
            }
        }));
        formData.append('map', JSON.stringify(map));
        for (const fileTuple of fileTuples) {
            formData.append(...fileTuple);
        }
        const response = await fetch(env.graphqlEndpoint, {
            method: 'POST',
            headers,
            body: formData,
        });
        const respJson = await response.json();
        console.timeEnd('mutateRecord');
        console.groupEnd();
        return respJson;
    }
    catch (err) {
        console.error(err);
        console.timeEnd('mutateRecord');
        console.groupEnd();
        throw err;
    }
}
export async function deleteRecordMutation(recordId, appId) {
    try {
        console.time('deleteRecordMutation');
        const headers = new Headers({
            'content-type': 'application/json',
            'authorization': `Bearer ${await getValidAccessToken()}`,
        });
        const response = await fetch(env.graphqlEndpoint, {
            body: JSON.stringify({
                query: gql `
                    mutation deleteRecords($appParams: String!, $recordIds: [String!]!, $appId: String!) {
                        deleteRecordsV1(appId: "5b9104c47a998d7abd016859", template: "mydata", params: $appParams, recordIds: $recordIds, datasource: {id: "data", appId: $appId}) {
                            id
                        }
                    }
                `,
                variables: {
                    appId,
                    appParams: `{"appid": "${appId}"}`,
                    recordIds: [recordId],
                },
                operationName: 'deleteRecords',
            }),
            headers,
            method: 'POST'
        });
        const json = await response.json();
        console.debug(json);
        console.timeEnd('deleteRecordMutation');
        return {};
    }
    catch (error) {
        return { error };
    }
}
