/* eslint-disable @typescript-eslint/camelcase */
import { getDB } from './db';
import { NoLocalUrlError } from '../../utils/errors';
import { registerSyncEvent } from '../backgroundsync';
export async function storeFile(file) {
    console.time('storeFile');
    console.info(`[PouchDB] store file ${file.url} in local db`);
    console.group();
    const fileDB = await getDB('file');
    if (file.url.startsWith('idb://')) {
        try {
            await fileDB.put({
                _id: file.url,
                ...file
            });
            const doc = await fileDB.get(file.url);
            await fileDB.putAttachment(file.url, 'data', doc._rev, file.data, file.mimetype);
            console.timeEnd('storeFile');
            console.info(`[PouchDB] stored file`);
            console.groupEnd();
        }
        catch (error) {
            console.timeEnd('storeFile');
            console.error(`[PouchDB] `, error);
            console.groupEnd();
            throw error;
        }
    }
    else {
        console.timeEnd('storeFile');
        console.error(`[PouchDB] tried to store non local file`);
        console.groupEnd();
        throw new NoLocalUrlError();
    }
}
export async function deleteFile(fileUrl) {
    console.time('deleteFile');
    console.info(`[PouchDB] delete file ${fileUrl}`);
    console.group();
    const fileDB = await getDB('file');
    if (fileUrl.startsWith('idb://')) {
        try {
            const doc = await fileDB.get(fileUrl);
            await fileDB.put({
                ...doc,
                _deleted: true,
            });
            console.timeEnd('deleteFile');
            console.info(`[PouchDB] deleted file ${fileUrl}`);
            console.group();
        }
        catch (error) {
            console.timeEnd('deleteFile');
            console.error(`[PouchDB] `, error);
            console.groupEnd();
        }
    }
    else {
        console.timeEnd('deleteFile');
        console.error(`[PouchDB] tried to store non local file`);
        console.groupEnd();
        throw new NoLocalUrlError();
    }
}
export async function getFile(fileUrl) {
    console.time('getFile');
    console.info(`[PouchDB] get file ${fileUrl} from local db`);
    console.group();
    const fileDB = await getDB('file');
    if (fileUrl.startsWith('idb://')) {
        try {
            const doc = await fileDB.get(fileUrl);
            doc.data = new File([await fileDB.getAttachment(fileUrl, 'data')], doc.fileName, {
                type: doc.mimetype,
            });
            doc.data;
            console.timeEnd('getFile');
            console.info(`[PouchDB] got file ${fileUrl} from local db`);
            console.groupEnd();
            return doc;
        }
        catch (error) {
            console.timeEnd('getFile');
            console.error(`[PouchDB] `, error);
            console.groupEnd();
        }
    }
    else {
        console.timeEnd('getFile');
        console.error(`[PouchDB] tried to store non local file`);
        console.groupEnd();
        throw new NoLocalUrlError();
    }
}
export async function clearFileDB() {
    console.time('clearFileDB');
    console.info(`[PouchDB] clearsfiledb`);
    console.group();
    const fileDB = await getDB('file');
    const allDocs = await fileDB.allDocs({});
    ///@ts-ignore
    fileDB.bulkDocs(allDocs.rows.map(row => ({
        _id: row.id,
        _rev: row.value.rev,
        _deleted: true,
    })));
    console.timeEnd('clearFileDB');
    console.groupEnd();
}
export async function storeRecordMutation(record) {
    console.time('storeRecordMutation');
    console.info(`[PouchDB] store RecordMutation for record ${record.id}`);
    console.group();
    const recordMutationDB = await getDB('recordMutation');
    try {
        let oldDoc;
        try {
            oldDoc = await recordMutationDB.get(record.id);
        }
        catch (_a) {
            oldDoc = undefined;
        }
        await recordMutationDB.put({
            ...oldDoc,
            _id: record.id,
            ...record,
        });
        registerSyncEvent();
        console.timeEnd('storeRecordMutation');
        console.info(`[PouchDB] stored recordmutation`);
        console.groupEnd();
    }
    catch (error) {
        console.timeEnd('storeRecordMutation');
        console.error(`[PouchDB] `, error);
        console.groupEnd();
        throw error;
    }
}
export async function removeRecordMutation(recordId) {
    console.time('removeRecordMutation');
    console.info(`[PouchDB] remove RecordMutation for record ${recordId}`);
    console.group();
    const recordMutationDB = await getDB('recordMutation');
    try {
        let oldDoc;
        try {
            oldDoc = await recordMutationDB.get(recordId);
        }
        catch (_a) {
            oldDoc = undefined;
        }
        ///@ts-ignore
        await recordMutationDB.remove({ _id: recordId, _rev: oldDoc._rev });
        console.timeEnd('removeRecordMutation');
        console.info(`[PouchDB] removed recordmutation`);
        console.groupEnd();
    }
    catch (error) {
        console.timeEnd('removeRecordMutation');
        console.error(`[PouchDB] `, error);
        console.groupEnd();
        throw error;
    }
}
export async function shiftRecordMutation() {
    var _a;
    console.time('shiftRecordMutation');
    console.info('[PouchDB] shiftRecordMutation');
    console.group();
    const recordMutationDB = await getDB('recordMutation');
    try {
        const allDocs = await recordMutationDB.allDocs({ include_docs: true });
        const doc = (_a = allDocs.rows[0]) === null || _a === void 0 ? void 0 : _a.doc;
        if (!doc) {
            console.timeEnd('shiftRecordMutation');
            console.groupEnd();
            return undefined;
        }
        await recordMutationDB.put({
            ...doc,
            _deleted: true,
        });
        delete doc._id;
        delete doc._rev;
        delete doc._conflicts;
        delete doc._attachments;
        console.timeEnd('shiftRecordMutation');
        console.groupEnd();
        return doc;
    }
    catch (error) {
        console.timeEnd('shiftRecordMutation');
        console.error(`[PouchDB] `, error);
        console.groupEnd();
        throw error;
    }
}
let timerCounter = 0;
/**
 * returns a record mutation based on the recordid.
 * If no mutation exists it just returns undefined.
 * @param recId
 */
export async function getRecordMutation(recId, tc = timerCounter++) {
    console.time('getRecordMutation' + tc);
    console.info(`[PouchDB] getRecordMutation ${tc} ${recId}`);
    console.group();
    const recordMutationDB = await getDB('recordMutation');
    try {
        const recDoc = await recordMutationDB.get(recId);
        console.timeEnd('getRecordMutation' + tc);
        console.groupEnd();
        return recDoc;
    }
    catch (err) {
        console.timeEnd('getRecordMutation' + tc);
        console.groupEnd();
        return undefined;
    }
}
