import { shiftRecordMutation, storeRecordMutation, clearFileDB } from './db/recordmutations';
import { mutateRecord } from './graphql';
import env from '../utils/env';
export async function waitUntilConnectionIsReady(count = 0) {
    try {
        await fetch(env.discoverEndpoint);
        console.info(`[BSYNC] got stable connection: ${(count) * 3} seconds`);
        await new Promise((resolve) => {
            setTimeout(resolve, 3000);
        });
    }
    catch (_a) {
        if (count > 10) {
            throw new Error('the connection is instable for more than 30 seconds');
        }
        console.info(`[BSYNC] got instable connection: ${(count) * 3} seconds`);
        return waitUntilConnectionIsReady(count + 1);
    }
}
export async function runBackgroundSync() {
    await waitUntilConnectionIsReady();
    try {
        let recordMutation = await shiftRecordMutation();
        while (recordMutation !== undefined) {
            try {
                console.log('replace mutation');
                await mutateRecord(recordMutation);
                recordMutation = await shiftRecordMutation();
            }
            catch (err) {
                await storeRecordMutation(recordMutation);
                throw err;
            }
        }
        await clearFileDB();
    }
    catch (err) {
        console.error(err);
    }
}
/**
 * registers a sync event on android, on ios it does nothing
 */
export function registerSyncEvent() {
    if (!env.iOsOnly) {
        console.info('[BSync] register bsync event');
        navigator.serviceWorker.ready.then(async (registry) => {
            if ('sync' in registry) {
                try {
                    await registry.sync.register('ll-bsync');
                }
                catch (_a) {
                    console.error('[BSync] failed to register bsync event');
                }
            }
        });
    }
}
